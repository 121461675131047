<template>
  <highcharts ref="chartRef" :options="chartOptions" />
</template>

<script setup>
import dayjs from "dayjs";
import { Chart as highcharts } from "highcharts-vue";
import { ref, unref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useSelectedGraphStyle } from "@/composables/useSelectedGraphStyle";

const prop = defineProps({
  viewdata: {
    type: Array,
    default: function () {
      return [];
    }
  },
  timeType: {
    type: String,
    default: "month"
  },
  labelSwitchStatus: {
    type: Boolean,
    default: false
  },
  timeseriesBarChartDataAverage: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["clickTimeseriesBar"]);

const i18n = useI18n();
const { isDarkMode, ...selectedGraphStyle } = useSelectedGraphStyle();
let clickedPointId = null;
const chartRef = ref(null);

const chartOptions = ref({
  chart: {
    renderTo: "container",
    type: "column",
    backgroundColor: "transparent"
  },
  title: {
    text: ""
  },
  tooltip: {
    shared: true,
    formatter: function () {
      return (
        dateFormat(this.x) +
        "<br>" +
        this.points[0].series.name +
        ": " +
        this.points[0].y
      );
    }
  },
  plotOptions: {
    column: {
      pointIntervalUnit: "month",
      dataLabels: {
        enabled: false,
        color: "#000000",
        formatter: function () {
          return this.y;
        }
      }
    },
    series: {
      cursor: "pointer",
      events: {
        click: function (e) {
          pointClickAndUpdateStyle(e, this.chart);
          onBarClick(e);
        }
      }
    }
  },
  xAxis: {
    type: "datetime",
    crosshair: true,
    labels: {
      rotation: -45,
      max: null,
      min: null
    }
  },
  legend: {
    enabled: false
  },
  yAxis: [
    {
      title: {
        text: ""
      },
      plotLines: [
        {
          color: "#f7a35c",
          width: 2,
          value: null,
          dashStyle: "Solid",
          zIndex: 5
        }
      ]
    }
  ],
  credits: { enabled: false },
  series: []
});

watch(
  () => prop.viewdata,
  () => {
    renderChart();
  }
);

watch(
  () => prop.labelSwitchStatus,
  () => {
    unref(chartOptions).plotOptions.column.dataLabels.enabled =
      prop.labelSwitchStatus;
  }
);

function dateFormat(date) {
  return prop.timeType === "month"
    ? dayjs(date).format("YYYY-M")
    : dayjs(date).format("YYYY-MM-DD");
}

function renderChart() {
  const chart = chartRef.value?.chart;
  resetPreviousPointStyle(chart);

  unref(chartOptions).series = [];
  unref(chartOptions).plotOptions.column.pointIntervalUnit = prop.timeType;
  unref(chartOptions).plotOptions.column.dataLabels.enabled =
    prop.labelSwitchStatus;
  if (isDarkMode.value) {
    unref(chartOptions).plotOptions.column.dataLabels.color = "#ffffff";
  }
  unref(chartOptions).yAxis[0].plotLines[0].value =
    prop.timeseriesBarChartDataAverage;

  const data = prop.viewdata.map(el => {
    return [dayjs(el.date).valueOf(), el["exceed_tolerance_count"]];
  });

  unref(chartOptions).series.push({
    type: "column",
    name: i18n.t("manual_adjustment_amount.exceed_counts"),
    yAxis: 0,
    zIndex: 0,
    data: data
  });

  unref(chartOptions).yAxis[0].title.text = i18n.t(
    "manual_adjustment_amount.exceed_counts_yaxis_label"
  );

  // 棒グラフが1本になるとX軸表示が15:00:00.000になるため
  // データ件数が1件の場合にX軸の表示フォーマットを指定する
  if (prop.viewdata.length === 1) {
    if (prop.timeType === "month") {
      unref(chartOptions).xAxis.labels.formatter = function () {
        return dayjs(this.value).format("MMM 'YY");
      };
    } else {
      unref(chartOptions).xAxis.labels.formatter = function () {
        return dayjs(this.value).format("D MMM");
      };
    }
  } else if (["day", "week"].includes(prop.timeType)) {
    /*
     * 「月」で指定したフォーマットが「日」「週」にも適用される場合はデフォルトに戻す
     * データ範囲が例えば7/1～7/15の場合、月では棒グラフは1本だが、週や日は複数になる。
     * デフォルト表示が月であり初期表示は月のフォーマットが週や日に適用されるため、日と週に切り替えた場合はデフォルト（D MMMなど）に戻す必要がある。
     */
    unref(chartOptions).xAxis.labels.formatter = null;
  }
}

function resetPreviousPointStyle(chart) {
  if (clickedPointId && chart) {
    const prevPoint = chart.get(clickedPointId);
    if (prevPoint) {
      prevPoint.update({ borderColor: null, borderWidth: 0 });
    }
  }
}

function pointClickAndUpdateStyle(event, chart) {
  resetPreviousPointStyle(chart);

  event.point.update({
    borderColor: selectedGraphStyle.borderColor.value,
    borderWidth: selectedGraphStyle.borderWidth
  });
  clickedPointId = event.point.id;
}

function onBarClick(event) {
  const orderScatterPlotStatus = true;
  const targetDate = dayjs(event.point.x).format("YYYY-MM-DD");

  // 散布図を表示するためのステータス、選択した棒グラフの日付、選択した時間集計区分を親コンポーネントに送る
  emit("clickTimeseriesBar", orderScatterPlotStatus, targetDate, prop.timeType);
}
</script>
