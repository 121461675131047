<template>
  <v-container class="pa-0" fluid>
    <alart-snack-bar :alart-obj="alartObj" />
    <alert-snack-bar-multi
      v-model="snackbar.popSnackBar"
      :type="snackbar.snackBarType"
      :title="snackbar.snackBarTitle"
      :message="snackbar.snackBarMessage"
    />
    <v-alert v-if="errored" type="warning">
      {{ i18n.t("manual_adjustment_amount.error_msg") }}
    </v-alert>
    <loading-bar :loading="isdataloading" />
    <v-row v-if="isShowTopMessage" class="ma-0">
      <v-col class="pa-4 pb-0" cols="12">
        <v-card class="translucent" rounded="0" flat color="grey-lighten-2">
          <v-card-title class="text-black">{{ showTopMessage }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-0 ml-1">
      <v-col cols="auto">
        <div class="process-select mt-2">
          <v-select
            v-model="selectedItem"
            :items="formattedItems"
            item-title="label"
            item-value="value"
            variant="solo"
            :label="i18n.t('manual_adjustment_amount.select_label')"
            :disabled="isDisableSelectBox"
            @update:model-value="changeProcess"
          />
        </div> </v-col
      ><v-spacer />
      <v-col cols="auto">
        <v-btn
          class="mt-2"
          :disabled="isDisableCsvOutputButton"
          :loading="isRequestingCsv"
          @click="downloadCSV"
        >
          <v-icon start> mdi-tray-arrow-down </v-icon>
          {{ i18n.t("manual_adjustment_amount.csv_download") }}
        </v-btn>
      </v-col>
      <v-col v-if="isShowCsvUploadButton" cols="auto">
        <v-btn
          class="mt-2"
          :disabled="isDisableCsvOutputButton"
          :loading="isRequestingCsv"
          @click="showCsvUploadDialog = true"
        >
          <v-icon start> mdi-tray-arrow-up </v-icon>
          {{ i18n.t("manual_adjustment_amount.csv_upload") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-n6">
      <v-col cols="12">
        <bar-chart
          :viewdata="overviewDisp"
          @click-target-component-note="clickTargetComponentNote"
        />
      </v-col>
      <v-col v-if="isShowTimeseriesBarChart" cols="12">
        <v-card rounded="0" height="100%">
          <loading-overlay
            :is-loading="isTimeseriesBarChartLoading"
            text-key="manual_adjustment_amount.timeseries_loading"
          />
          <v-toolbar density="compact" flat class="bg-transparent">
            <v-toolbar-title>
              {{ i18n.t("manual_adjustment_amount.timeseries_chart") }}
              {{ selectedComponentNote }}
            </v-toolbar-title> </v-toolbar
          ><v-toolbar
            density="compact"
            class="bg-transparent text-body-2 font-weight-medium"
            flat
          >
            <toggle-buttons
              :contents="timeButtons"
              :default="'month'"
              class="px-4"
              @select-button="changeTimescale" />
            <v-spacer />{{ i18n.t("others.switch.stack_label") }}
            <v-switch
              v-model="isLabelSwitchOn"
              color="primary"
              inset
              class="flex-grow-0 pt-6 pl-3 pr-6"
          /></v-toolbar>
          <timeseries-bar-chart
            :viewdata="timeseriesDisp"
            :time-type="timeType"
            :label-switch-status="isLabelSwitchOn"
            :timeseries-barChart-data-average="timeseriesBarChartDataAverage"
            @click-timeseries-bar="clickTimeseriesBar"
          />
        </v-card>
      </v-col>
      <v-col v-if="isShowOrderScatterPlot" cols="12">
        <v-card rounded="0" height="100%">
          <loading-overlay :is-loading="isOverlayVisible" />
          <v-toolbar density="compact" flat class="bg-transparent">
            <v-toolbar-title>
              {{ i18n.t("manual_adjustment_amount.order_scatter_plot") }}
              {{ selectedComponentNote }}
            </v-toolbar-title> </v-toolbar
          ><v-toolbar
            density="compact"
            class="bg-transparent text-body-2 font-weight-medium"
            flat
          ></v-toolbar>
          <order-scatter-plot
            :viewdata="scatterPlotDisp"
            @click-order-scatter-plot="clickOrderScatterPlot"
          />
        </v-card>
      </v-col>
      <v-col v-if="isShowOrderInfo" cols="12">
        <v-card rounded="0" height="100%">
          <v-row class="ma-2">
            <order-info
              :viewdata="orderInfoDisp"
              :table-error-data="tableErrorDataDisp"
              :order-prod-data="orderProdDisp"
              :order-info-loading-status="isOrderInfoLoading"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <csv-upload-dialog
      :show-csv-upload-dialog="showCsvUploadDialog"
      @open-status-update-request="updateCsvUploadDialogStatusHandler"
      @refresh-graph-request="refreshGraphHandler"
      @api-result-request="apiResultHandler"
    />
  </v-container>
</template>

<script setup>
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref, unref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import BarChart from "@/components/manualadjustmentamount/BarChart.vue";
import TimeseriesBarChart from "@/components/manualadjustmentamount/TimeseriesBarChart.vue";
import OrderScatterPlot from "@/components/manualadjustmentamount/OrderScatterPlot.vue";
import OrderInfo from "@/components/manualadjustmentamount/OrderInfo.vue";
import CsvUploadDialog from "@/components/manualadjustmentamount/CsvUploadDialog.vue";
import AlartSnackBar from "@/components/parts/AlartSnackBar.vue";
import LoadingOverlay from "@/components/parts/LoadingOverlay.vue";
import AlertSnackBarMulti from "@/components/parts/AlertSnackBarMulti.vue";
import LoadingBar from "@/components/parts/LoadingBar.vue";
import {
  connectToAPI,
  isMonday,
  isSunday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  getMonday,
  replaceUndefinedWithNA,
  replaceInvalidFluteWithNA,
  convertToFixedPointNumberOrNA,
  translateDcInformation
} from "@/helpers/util";
import { getErrorMaster } from "@/helpers/api/getErrorMaster";
import { getErrorSeries } from "@/helpers/api/getErrorSeries";
import { getOrderProd } from "@/helpers/api/getOrderProd";
import { useErrorMasterStore } from "@/stores/errorMaster";
import { useUserinfo } from "@/stores/userinfo";
import { SHA256 } from "crypto-js";
import { useFilterDataStore } from "@/stores/filterData";
import { usePreference } from "@/stores/preference";
import ToggleButtons from "@/components/parts/ToggleButtons.vue";

import ManualAdjustmentAmountToleranceCsvUploadTargets from "@/assets/manual_adjustment_amount_tolerance_csv_upload_targets.json";

const i18n = useI18n();
const errorMasterStore = useErrorMasterStore();
const filterDataStore = useFilterDataStore();
const preferenceStore = usePreference();

const { errorMaster } = storeToRefs(errorMasterStore);
const userinfoStore = useUserinfo();
const { email } = storeToRefs(userinfoStore);
const { language, unitInch } = storeToRefs(preferenceStore);

const { filterData: stateFilterData } = storeToRefs(filterDataStore);
const alartObj = ref({
  flg: false,
  text: i18n.t("others.alart.apierror")
});

const overview = ref([]);
const barChartData = ref([]);
const isdataloading = ref(false);
const errored = ref(false);
const isShowTopMessage = ref(true);
const showTopMessage = ref("");
const clearAllData = ref(true);
const selectedItem = ref(null);
const timeseries = ref([]);
const isShowTimeseriesBarChart = ref(false);
const timeseriesBarChartData = ref([]);
const timeseriesBarChartDataAverage = ref(null);
const isTimeseriesBarChartLoading = ref(false);
const orders = ref([]);
const isOrderScatterPlotLoading = ref(false);
const orderScatterPlotData = ref([]);
const isShowOrderScatterPlot = ref(false);
const isOrderInfoLoading = ref(false);
const orderInfoData = ref([
  { label: i18n.t("productivity.order_id"), key: "order_id", val: null },
  { label: i18n.t("productivity.order_no"), key: "order_no", val: null },
  {
    label: i18n.t("productivity.repeat_order_no"),
    key: "repeat_order_no",
    val: null
  },
  {
    label: i18n.t("productivity.plant"),
    key: "city_plant_no",
    val: null
  },
  { label: i18n.t("productivity.volume"), key: "prod_volume", val: null },
  {
    label: "",
    key: "prod_volume_size",
    val: null
  },
  {
    label: i18n.t("productivity.loss_sheets"),
    key: "loss_volume",
    val: null
  },
  {
    label: i18n.t("productivity.loss_rate"),
    key: "loss_rate",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: "",
    key: "box_size_mm",
    val: null
  },
  { label: i18n.t("productivity.bundle"), key: "bundle", val: null },
  {
    label: i18n.t("productivity.sc_os_slotter_input_value"),
    key: "sc_os_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_ds_slotter_input_value"),
    key: "sc_ds_slotter_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_register_input_value"),
    key: "sc_register_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.sc_box_depth_input_value"),
    key: "sc_box_depth_input_value",
    val: null
  },
  {
    label: i18n.t("productivity.dc_information"),
    key: "dc_information",
    val: null
  },
  {
    label: i18n.t("productivity.flute"),
    key: "flute_str",
    val: null
  },
  {
    label: i18n.t("productivity.start_time"),
    key: "prod_start_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.end_time"),
    key: "prod_end_time_local",
    val: null
  },
  {
    label: i18n.t("productivity.production_time"),
    key: "prod_time",
    val: null
  },
  { label: i18n.t("productivity.stop_time"), key: "stop_time", val: null },
  {
    label: i18n.t("productivity.stop_count"),
    key: "stop_count",
    val: null
  },
  {
    label: i18n.t("productivity.stop_count_volume"),
    key: "stop_count_per_vol",
    val: null
  },
  {
    label: i18n.t("productivity.speed_ratio"),
    key: "prod_speed_ratio",
    val: null,
    dig: 100,
    unit: "%"
  },
  {
    label: i18n.t("productivity.productivity_per_order"),
    key: "productivity",
    val: null,
    dig: 100,
    unit: "%"
  }
]);
const tableErrorData = ref([]);
const orderProdData = ref([]);
const errorMaster_obj = ref(null);
const isShowOrderInfo = ref(false);
const selectedProcess = ref("");
const selectedComponentNote = ref("");
const isDisableCsvOutputButton = ref(false);
const isRequestingCsv = ref(false);
const isDisableSelectBox = ref(false);
const showCsvUploadDialog = ref(false);
const snackbar = ref({
  popSnackBar: false,
  snackBarType: "",
  snackBarTitle: "",
  snackBarMessage: ""
});
const isLabelSwitchOn = ref(false);

const apiResultHandler = apiResult => {
  snackbar.value.snackBarType = apiResult.snackBarType;
  snackbar.value.snackBarTitle = apiResult.snackBarTitle;
  snackbar.value.snackBarMessage = apiResult.snackBarMessage;
  snackbar.value.popSnackBar = apiResult.popSnackBar;
};

const overviewDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(barChartData);
});
const timeseriesDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(timeseriesBarChartData);
});
const scatterPlotDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderScatterPlotData);
});
const orderInfoDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderInfoData);
});
const orderProdDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(orderProdData);
});
const tableErrorDataDisp = computed(() => {
  if (unref(clearAllData)) {
    return [];
  }
  return unref(tableErrorData);
});
const formattedItems = computed(() => {
  const uniqueValues = Array.from(
    new Set(unref(overview).map(item => item.process))
  );
  const uniqueValuesAddLable = uniqueValues.map(value => {
    const item = unref(overview).find(item => item.process === value);
    return {
      value,
      label: i18n.rt(i18n.tm("manual_adjustment_amount")[item.process])
    };
  });
  const addAllOption = {
    value: "select_all",
    label: i18n.rt(i18n.tm("manual_adjustment_amount")["select_all"])
  };
  return [addAllOption, ...uniqueValuesAddLable];
});
const isShowCsvUploadButton = computed(() => {
  const email_hash = SHA256(email.value).toString();
  return ManualAdjustmentAmountToleranceCsvUploadTargets.includes(email_hash);
});
const isOverlayVisible = computed(() => {
  return isOrderScatterPlotLoading.value || isOrderInfoLoading.value;
});

const timeButtons = ref(["day", "week", "month"]);
const timeType = ref("month");

watch(
  stateFilterData,
  async value => {
    if (value.selectplantid.length !== 1 || !value.from || !value.to) {
      isShowTopMessage.value = true;
      showTopMessage.value = i18n.rt(
        i18n.tm("manual_adjustment_amount.message")
      );
      resetAllSelect();

      isDisableCsvOutputButton.value = true;
      isDisableSelectBox.value = true;
      clearAllData.value = true;
      return;
    }
    resetAllSelect();

    isShowTimeseriesBarChart.value = false;
    isDisableCsvOutputButton.value = false;
    isDisableSelectBox.value = false;

    isdataloading.value = true;
    errored.value = false;
    isShowTopMessage.value = false;
    clearAllData.value = false;

    await getOverview()
      .catch(() => {
        isdataloading.value = false;
        errored.value = true;
      })
      .then(() => {
        prepareData();
        selectedItem.value = null;
        isdataloading.value = false;
      });
  },
  {
    deep: true,
    immediate: true
  }
);

onMounted(() => {
  // オーダー情報テーブルの項目でメートルかインチで表示初期表示
  if (unref(unitInch)) {
    unref(orderInfoData)[5].label = i18n.t("productivity.volume_size_in");
    unref(orderInfoData)[8].label = i18n.t("productivity.box_size_in");
  } else {
    unref(orderInfoData)[5].label = i18n.t("productivity.volume_size_mm");
    unref(orderInfoData)[8].label = i18n.t("productivity.box_size_mm");
  }
});

async function getOverview() {
  timeType.value = "month";

  const reqOptions = {
    method: "GET",
    url: "/api/manualadjustmentamount/count",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to
    }
  };
  await connectToAPI(reqOptions, unref(alartObj))
    .catch(error => {
      throw error;
    })
    .then(response => {
      if (response.data.length == 0) {
        isShowTopMessage.value = true;
        showTopMessage.value = i18n.rt(
          i18n.tm("manual_adjustment_amount.empty_data_msg")
        );
        isDisableCsvOutputButton.value = true;
        isDisableSelectBox.value = true;
      }
      overview.value = response.data;
    });
}

function prepareData() {
  barChartData.value = unref(overview);
}

/**
 * 全てのグラフの選択状態を解除
 */
function resetAllSelect() {
  // 閾値越え回数棒グラフをリセット
  selectedItem.value = null;

  // 閾値越え回数棒グラフ以外の下部に表示されるグラフをリセット
  resetBelowCharts();
}

/**
 * 閾値越え回数棒グラフ以外の下部に表示されるグラフの選択をリセット・非表示
 */
function resetBelowCharts() {
  // 時系列棒グラフをリセット・非表示
  // selectedComponentNoteとselectedProcessは閾値越え回数のグラフで選択するが、選択が反映されるのは時系列棒グラフである
  selectedComponentNote.value = "";
  selectedProcess.value = "";

  isShowTimeseriesBarChart.value = false;
  timeType.value = "month";
  isLabelSwitchOn.value = false;

  // オーダー散布図を非表示
  isShowOrderScatterPlot.value = false;

  // オーダー詳細グラフを非表示
  isShowOrderInfo.value = false;
}

/*
 * processを選択すると選択したprocessに紐づく対象装置のデータを抽出する
 */
async function changeProcess() {
  resetBelowCharts();

  isdataloading.value = true;
  await getSelectProcessData(unref(selectedItem));
}
async function getSelectProcessData(selectedItem) {
  await getOverview()
    .catch(() => {
      isdataloading.value = false;
      errored.value = true;
    })
    .then(() => {
      prepareData();
      isdataloading.value = false;
    });
  if (selectedItem === "select_all") {
    barChartData.value = unref(overview);
  } else {
    const filteredArray = unref(overview).filter(
      item => item.process === selectedItem
    );
    barChartData.value = [...filteredArray];
  }
}
async function getTimeseries(process, component_note) {
  const reqOptions = {
    method: "GET",
    url: "/api/manualadjustmentamount/timeseries",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to,
      process: process,
      component_note: component_note
    }
  };

  const response = await connectToAPI(reqOptions, unref(alartObj));

  return response.data;
}
function prepareTimeseriesData() {
  timeseriesBarChartData.value = unref(overview);
}
/*
 * 指定のフォーマット形式の日時で閾値超え回数を集計する
 */
function aggregateTimeseriesData(data, timeType) {
  const result = {};

  data.forEach(element => {
    let dateKey;

    if (timeType === "month") {
      dateKey = dayjs(element.date).format("YYYY-MM");
    } else if (timeType === "week") {
      dateKey = element.week_start;
    }

    if (result[dateKey]) {
      result[dateKey] += element.exceed_tolerance_count;
    } else {
      result[dateKey] = element.exceed_tolerance_count;
    }
  });

  return Object.keys(result).map(function (date) {
    return {
      date: date,
      exceed_tolerance_count: result[date]
    };
  });
}
/*
 * 時系列グラフの平均値を算出する
 * データが1件以下の場合はnull（=平均線は出さない）
 */
function calculateAverageByPeriod(data, timeType) {
  const fromDate = unref(stateFilterData).from;
  const toDate = unref(stateFilterData).to;

  const getExcludedDataOfTargetDate = (data, targetDate) =>
    data.filter(obj => obj.date !== targetDate);

  const calculateAverage = data =>
    data.reduce((sum, obj) => sum + obj.exceed_tolerance_count, 0) /
    data.length;

  const handleMonthFiltering = (data, fromDate, modifiedToDate) => {
    // 月のデータ構造: dateに年月、exceed_tolerance_countに月の合計値を持つ。
    // fromDate/toDateが中途半端な場合はその月を除外し、月が揃ったデータのみを使用。

    // フィルターの日付が月の初日か最終日かを判断する
    const isFirstDayOfMonthResult = isFirstDayOfMonth(dayjs(fromDate));
    const isLastDayOfMonthResult = isLastDayOfMonth(dayjs(modifiedToDate));

    let excludedData = data;
    if (!isFirstDayOfMonthResult) {
      excludedData = getExcludedDataOfTargetDate(
        excludedData,
        dayjs(fromDate).format("YYYY-MM")
      );
    }
    if (!isLastDayOfMonthResult) {
      excludedData = getExcludedDataOfTargetDate(
        excludedData,
        dayjs(modifiedToDate).format("YYYY-MM")
      );
    }
    return excludedData;
  };

  const handleWeekFiltering = (data, fromDate, modifiedToDate) => {
    // 週のデータ構造: dateに月曜日の日付、exceed_tolerance_countに週の合計値を持つ。
    // fromDate/toDateが中途半端な場合はその週を除外し、週が揃ったデータのみを使用。

    // フィルターの日付が月曜日か日曜日かを判断する
    const isMondayResult = isMonday(dayjs(fromDate));
    const isSundayResult = isSunday(dayjs(modifiedToDate));

    let excludedData = data;
    if (!isMondayResult) {
      // 週の月曜日を取得
      const mondayFromDate = getMonday(dayjs(fromDate));
      excludedData = getExcludedDataOfTargetDate(excludedData, mondayFromDate);
    }
    if (!isSundayResult) {
      // 週の月曜日を取得
      const mondayToDate = getMonday(dayjs(modifiedToDate));
      excludedData = getExcludedDataOfTargetDate(excludedData, mondayToDate);
    }
    return excludedData;
  };

  let filteredData;

  // toDateが現在より未来の場合は現在日に置き換える
  const today = dayjs();
  const toDateDayjs = dayjs(toDate);
  const modifiedToDate = toDateDayjs.isAfter(today)
    ? today.format("YYYY-MM-DD")
    : toDate;

  if (timeType === "month") {
    filteredData = handleMonthFiltering(data, fromDate, modifiedToDate);
  } else if (timeType === "week") {
    filteredData = handleWeekFiltering(data, fromDate, modifiedToDate);
  } else {
    filteredData = data;
  }

  let average = null;
  if (filteredData.length > 1) {
    average = calculateAverage(filteredData);
  }

  return average;
}
/*
 * 選択した時間形式（日、週、月）のデータを作成する
 */
function changeTimescale(selectedTimeType) {
  timeType.value = selectedTimeType;

  // dailyの場合は変換処理は行わない
  if (timeType.value === "month" || timeType.value === "week") {
    timeseriesBarChartData.value = aggregateTimeseriesData(
      unref(timeseries),
      unref(timeType)
    );

    timeseriesBarChartDataAverage.value = calculateAverageByPeriod(
      timeseriesBarChartData.value,
      unref(timeType)
    );
  } else {
    timeseriesBarChartData.value = unref(timeseries);

    timeseriesBarChartDataAverage.value = calculateAverageByPeriod(
      unref(timeseries),
      unref(timeType)
    );
  }
}
/*
 * 選択した対象装置の時系列データを取得する
 */
async function getTimeseriesData(process, component_note) {
  try {
    const data = await getTimeseries(process, component_note);

    timeseries.value = data;
    prepareTimeseriesData();

    if (timeType.value === "month" || timeType.value === "week") {
      timeseriesBarChartData.value = aggregateTimeseriesData(
        unref(timeseries),
        unref(timeType)
      );

      timeseriesBarChartDataAverage.value = calculateAverageByPeriod(
        timeseriesBarChartData.value,
        unref(timeType)
      );
    } else {
      timeseriesBarChartData.value = unref(timeseries);

      timeseriesBarChartDataAverage.value = calculateAverageByPeriod(
        unref(timeseries),
        unref(timeType)
      );
    }

    // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
    errored.value = false;
  } catch (error) {
    errored.value = true;
  } finally {
    isdataloading.value = false;
  }
}
/*
 * 棒グラフでクリックした対象装置の情報を展開
 */
async function clickTargetComponentNote(selectComponentNoteInfo) {
  isTimeseriesBarChartLoading.value = true;
  isShowTimeseriesBarChart.value = selectComponentNoteInfo.showFlag;
  isShowOrderScatterPlot.value = false;
  isShowOrderInfo.value = false;
  selectedProcess.value = selectComponentNoteInfo.process;
  selectedComponentNote.value = selectComponentNoteInfo.component_note;
  await getTimeseriesData(
    selectComponentNoteInfo.process,
    selectComponentNoteInfo.component_note
  );
  isTimeseriesBarChartLoading.value = false;
}
/*
 * オーダ情報取得APIを実行する
 */
async function getOrders(targetDate, process, component_note, timeType) {
  const reqOptions = {
    method: "GET",
    url: "/api/manualadjustmentamount/orders",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      target_date: targetDate,
      process: process,
      component_note: component_note,
      time_type: timeType
    }
  };
  const response = await connectToAPI(reqOptions, unref(alartObj));

  return response.data;
}
/*
 * 測定装置の時系列棒グラフでクリックした日付のオーダー情報を展開
 */
async function clickTimeseriesBar(
  orderScatterPlotStatus,
  targetDate,
  timeType
) {
  isShowOrderInfo.value = false;
  isOrderScatterPlotLoading.value = true;
  isShowOrderScatterPlot.value = orderScatterPlotStatus;

  try {
    const data = await getOrders(
      targetDate,
      selectedProcess.value,
      selectedComponentNote.value,
      timeType
    );
    orders.value = data;
    orderScatterPlotData.value = unref(orders);

    // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
    errored.value = false;
  } catch (error) {
    errored.value = true;
  } finally {
    isOrderScatterPlotLoading.value = false;
  }
}
/**
 * オーダーで発生したエラーを取得する
 */
async function getErrorSeriesData(order_id) {
  try {
    if (unref(errorMaster) !== null && unref(errorMaster) !== undefined) {
      errorMaster.value = await getErrorMaster(
        unref(errorMaster),
        unref(language),
        unref(alartObj)
      );
    }

    tableErrorData.value = [];

    const errordata = await getErrorSeries(order_id, unref(alartObj));

    //ブランクだったらerrorMaster_obj作る
    //エラーマスタのデータを扱いやすいようにオブジェクトに変更、キーをerrorcodeにする
    //まずフィルタでerrorcodeがひとつのもののみをとって、reduceで変換
    if (!unref(errorMaster_obj)) {
      errorMaster_obj.value = unref(errorMaster)
        .filter(value => value.errorcode.length == 1)
        .reduce((obj, value) => {
          obj[value.errorcode[0].toUpperCase()] = {
            meaning: value.meaning
              ? value.meaning[0].replaceAll("<br>", "\n")
              : null,
            detect_condition: value.meaning
              ? value.detect_condition.replaceAll("<br>", "\n")
              : null,
            countermeasure: value.meaning
              ? value.countermeasure.replaceAll("<br>", "\n")
              : null,
            cause_of_occurrence: value.cause_of_occurrence
              ? value.cause_of_occurrence.replaceAll("<br>", "\n")
              : null
          };
          return obj;
        }, {});
    }

    let before_t = "";
    let before_err_list = [];
    let before_err_list_obj = [];
    let id_err = 0;
    if (errordata) {
      for (let obj of errordata) {
        if (before_t === obj.t && !before_err_list.includes(obj.t)) {
          before_err_list.push(obj.err);
          before_err_list_obj.push({
            err: obj.err,
            meaning: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].meaning
              : null,
            countermeasure: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].countermeasure
              : null,
            detect_condition: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].detect_condition
              : null,
            cause_of_occurrence: unref(errorMaster_obj)[obj.err]
              ? unref(errorMaster_obj)[obj.err].cause_of_occurrence
              : null
          });
        } else {
          if (before_t !== "") {
            unref(tableErrorData).push({
              id: id_err,
              t: dayjs(before_t).format("YYYY-MM-DD HH:mm:ss"),
              err: before_err_list.join(","),
              err_list: before_err_list_obj
            });
            id_err++;
          }

          before_err_list = [obj.err];
          before_t = obj.t;
          before_err_list_obj = [
            {
              err: obj.err,
              meaning: unref(errorMaster_obj)[obj.err]
                ? unref(errorMaster_obj)[obj.err].meaning
                : null,
              countermeasure: unref(errorMaster_obj)[obj.err]
                ? unref(errorMaster_obj)[obj.err].countermeasure
                : null,
              detect_condition: unref(errorMaster_obj)[obj.err]
                ? unref(errorMaster_obj)[obj.err].detect_condition
                : null,
              cause_of_occurrence: unref(errorMaster_obj)[obj.err]
                ? unref(errorMaster_obj)[obj.err].cause_of_occurrence
                : null
            }
          ];
        }
      }
      unref(tableErrorData).push({
        id: id_err,
        t: dayjs(before_t).format("YYYY-MM-DD HH:mm:ss"),
        err: before_err_list.join(","),
        err_list: before_err_list_obj
      });
    }

    // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
    errored.value = false;
  } catch (error) {
    errored.value = true;
  }
}
/**
 * 散布図クリック時に生産データとって描画する
 */
async function getOrderProdData(order_id) {
  try {
    const resdata = await getOrderProd(order_id);

    if (resdata) {
      /*末尾のyが0だったら取り除く
       *オーダーが終わると機械が停止し累積生産量が0になるので、
       *グラフが急に下がることを避けるため、最後の0を省いていると推測
       */
      if (resdata.slice(-1)[0].y === 0) resdata.pop();
      //ミリ秒に変換
      resdata.forEach(element => {
        element.viewx = element.x;
        element.x = new Date(element.x);
      });
      orderProdData.value = resdata;

      // 正常に処理できているのでエラーアラートを無効にする（再実行時用）
      errored.value = false;
    }
  } catch (error) {
    errored.value = true;
  }
}
/*
 * オーダー散布図でクリックしたオーダーをテーブル用データへ展開
 */
async function clickOrderScatterPlot(orderInfoStatus, targetOrderId) {
  isOrderInfoLoading.value = true;
  isShowOrderInfo.value = orderInfoStatus;

  // 対象のオーダー情報を抽出する関数
  const getTargetOrderInfo = (orders, targetOrderId) => {
    return unref(orders).find(order => order.order_id === targetOrderId);
  };

  // 対象の項目を変換する関数
  const transformOrderInfo = orderInfo => {
    orderInfo.repeat_order_no = replaceUndefinedWithNA(
      orderInfo.repeat_order_no
    );
    orderInfo.sc_box_depth_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_box_depth_input_value
    );
    orderInfo.sc_ds_slotter_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_ds_slotter_input_value
    );
    orderInfo.sc_os_slotter_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_os_slotter_input_value
    );
    orderInfo.sc_register_input_value = convertToFixedPointNumberOrNA(
      orderInfo.sc_register_input_value
    );
    orderInfo.dc_information = translateDcInformation(
      orderInfo.dc_information,
      i18n.t
    );
    orderInfo.flute_str = replaceInvalidFluteWithNA(orderInfo.flute_str);
    orderInfo.stop_count_per_vol = replaceUndefinedWithNA(
      orderInfo.stop_count_per_vol
    );
  };

  const targetOrderInfo = getTargetOrderInfo(orders, targetOrderId);
  // 対象の項目を変換
  if (unref(targetOrderInfo)) {
    transformOrderInfo(unref(targetOrderInfo));

    // オーダー情報テーブル用データを展開
    unref(orderInfoData).forEach(obj => {
      obj.val = unref(targetOrderInfo)[obj.key];
    });

    // エラーテーブル用データを展開
    await getErrorSeriesData(unref(targetOrderInfo.order_id));

    // オーダーの生産量折れ線グラフ用データを展開
    await getOrderProdData(unref(targetOrderInfo.order_id));
  } else {
    // オーダー情報がないというsnackbarを表示
    snackbar.value.snackBarType = "warning";
    snackbar.value.snackBarTitle = i18n.rt(
      i18n.tm("manual_adjustment_amount.no_target_order")
    );
    snackbar.value.snackBarMessage = "";
    snackbar.value.popSnackBar = true;
  }

  isOrderInfoLoading.value = false;
}
/*
 * CSVデータ取得APIを実行する
 */
async function getAverageManualAdjustmentAmountCsv() {
  const reqOptions = {
    method: "GET",
    url: "/api/manualadjustmentamount/csv",
    params: {
      plant_id_dpac: unref(stateFilterData).selectplantid[0],
      date_from: unref(stateFilterData).from,
      date_to: unref(stateFilterData).to
    },
    responseType: "blob"
  };

  const response = await connectToAPI(reqOptions, unref(alartObj));
  return response.data;
}
/*
 * CSVをダウンロードする
 */
async function downloadCSV() {
  isRequestingCsv.value = true;
  try {
    const data = await getAverageManualAdjustmentAmountCsv();

    // CSV ファイルは「UTF-8 BOM有り」で出力
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    // CSVファイルを出力するために Blob 型のインスタンスを作る
    const blob = new Blob([bom, data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    const filename = `average_manual_adjustment_amount_per_order_${unref(stateFilterData).from}_${unref(stateFilterData).to}.csv`;
    a.download = filename;
    a.click();
    a.remove();

    errored.value = false;
  } catch (error) {
    errored.value = true;
  } finally {
    isRequestingCsv.value = false;
  }
}
/*
 * CSVアップロードダイアログ表示の制御
 */
function updateCsvUploadDialogStatusHandler(status) {
  showCsvUploadDialog.value = status;
}
/*
 * 閾値更新後の値でグラフの再描画をする
 */
function refreshGraphHandler(RefreshGraphRequest) {
  if (RefreshGraphRequest) {
    // 成功のsnackbarを表示
    snackbar.value.snackBarType = "success";
    snackbar.value.snackBarTitle = i18n.rt(
      i18n.tm("manual_adjustment_amount.csv_uploaded")
    );
    snackbar.value.snackBarMessage = "";
    snackbar.value.popSnackBar = true;

    isdataloading.value = true;

    // グラフの再描画を実行
    getSelectProcessData("select_all");

    resetAllSelect();
  }
}
</script>
<style scoped>
.process-select {
  display: inline-block;
  margin-right: 0.5rem;
  width: 400px;
}
</style>
